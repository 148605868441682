import React, { useEffect } from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet-async"

import jiggswapApi from "../api/jiggswap"

import LogoText from "../components/common/LogoText"

const WelcomeHero = () => {
  const tagline = "Swap puzzles with other puzzle lovers!"

  return (
    <>
      <section className="hero is-medium is-block homepage-hero">
        <div className="hero-body">
          <div className="columns is-centered is-vcentered">
            <div className="column  is-half has-text-centered has-text-centered-touch">
              <h1 className="title is-1 is-size-2-mobile is-spaced">
                Welcome to <LogoText />
                <span className="is-cursive">!</span>
              </h1>
              <p className="subtitle has-text-weight-bold">{tagline}</p>
              <div className="buttons is-inline-flex" style={{ marginTop: "2rem" }}>
                <Link to="/puzzles" className="button is-large is-danger has-text-weight-bold">
                  <i className="fas fa-puzzle-piece mr-1"></i>View Puzzles<i className="fas fa-puzzle-piece fa-flip-horizontal ml-1"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

const HomePage = () => {
  useEffect(() => {
    const hitHome = async () => {
      await jiggswapApi.get("/home")
    }

    hitHome()
  }, [])

  return (
    <React.Fragment>
      <Helmet>
        <meta property="og:site_name" content="Home - JiggSwap" />
        <meta property="og:title" content={"Trade Jigsaw Puzzles on JiggSwap"} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={"https://www.jiggswap.com"} />
        <meta property="og:image" content={"https://www.jiggswap.com/logo.png"} />
        <meta
          property="og:description"
          content={
            "Trade your jigsaw puzzles with other puzzle lovers. Jigsaw puzzle swaps are completely free. Get started trading your jigsaw puzzles today!"
          }
        />
        <meta
          name="description"
          content="Trade your jigsaw puzzles with other puzzle lovers. Jigsaw puzzle swaps are completely free. Get started trading your jigsaw puzzles today!"
        ></meta>
        <title>{"Home - JiggSwap"}</title>
        <meta http-equiv="content-language" content="en-us"></meta>
      </Helmet>

      <div className="site-content">
        <WelcomeHero />

        <hr />

        <section className="hero section is-block">
          <header className="has-text-centered" style={{ marginBottom: "3rem" }}>
            <h2 className="is-3 is-spaced title has-text-weight-bold">
              What's <LogoText />?
            </h2>
            <p className="subtitle">Jiggswap makes swapping puzzles with other puzzle lovers a breeze!</p>
            <p className="subtitle">You can trade in your old puzzles for fresh ones that you haven't done.</p>
            <p className="subtitle">Keep reading to find out how it works!</p>
          </header>

          <hr />

          <div className="columns is-variable is-8 is-vcentered has-text-centered-mobile">
            <div className="column is-half">
              <h3 className="title has-text-weight-bold is-spaced is-3">Add Your Puzzles</h3>
              <p className="subtitle">Put up the puzzles you want to trade away. This will let other users see your puzzles.</p>
              <p className="subtitle">Once you have added a puzzle, you can start doing trades!</p>
            </div>

            <div className="column">
              <figure className="image" style={{ maxWidth: "300px", margin: "0 auto" }}>
                <img src="https://cdn.jiggswap.com/assets/1_add_puzzle.svg" alt={"add puzzle"} />
              </figure>
            </div>
          </div>

          <hr />

          <div className="columns is-variable is-8 is-vcentered has-text-centered-mobile" style={{ flexDirection: "row-reverse" }}>
            <div className="column is-half">
              <h3 className="title has-text-weight-bold is-spaced is-3">Pick A Puzzle</h3>
              <p className="subtitle">Browse the collection of puzzles.</p>
              <p className="subtitle">Once you find one you'd like, you can click "I want this! Start a trade."</p>
            </div>

            <div className="column">
              <figure className="image" style={{ maxWidth: "300px", margin: "0 auto" }}>
                <img src="https://cdn.jiggswap.com/assets/2_pick_puzzle.svg" alt={"pick a puzzle"} />
              </figure>
            </div>
          </div>

          <hr />

          <div className="columns is-variable is-8 is-vcentered has-text-centered-mobile">
            <div className="column is-half">
              <h3 className="title has-text-weight-bold is-spaced is-3">Propose a Trade</h3>
              <p className="subtitle">You'll be prompted to choose one of your own puzzles in exchange.</p>
            </div>

            <div className="column">
              <figure className="image" style={{ maxWidth: "300px", margin: "0 auto" }}>
                <img src="https://cdn.jiggswap.com/assets/3_propose_trade.svg" alt={"propose a trade"} />
              </figure>
            </div>
          </div>

          <hr />

          <div className="columns is-variable is-8 is-vcentered has-text-centered-mobile" style={{ flexDirection: "row-reverse" }}>
            <div className="column is-half">
              <h3 className="title has-text-weight-bold is-spaced is-3">Ship Your Puzzle,</h3>
              <h3 className="title has-text-weight-bold is-spaced is-3">Get a Fresh Puzzle</h3>
              <p className="subtitle">
                Once your trade recipient agrees to your trade, you can mail them your puzzle, and they'll mail you yours.
              </p>
              <p className="subtitle">
                You'll even receive shipping and tracking information (if provided by your trade partner) while you wait for your fresh
                puzzle to arrive.
              </p>

              <p className="subtitle has-text-centered"> Easy! </p>
            </div>

            <div className="column">
              <figure className="image" style={{ maxWidth: "300px", margin: "0 auto" }}>
                <img src="https://cdn.jiggswap.com/assets/4_ship.svg" alt={"ship your puzzle and get a fresh puzzle"} />
              </figure>
            </div>
          </div>
        </section>

        <section className="section is-block has-text-centered">
          <Link to="/puzzles" className="button is-danger is-large has-text-weight-bold">
            <i className="fas fa-puzzle-piece mr-1"></i>View Puzzles<i className="fas fa-puzzle-piece fa-flip-horizontal ml-1"></i>
          </Link>
        </section>
      </div>
    </React.Fragment>
  )
}

export default HomePage
